import React, { useEffect } from 'react';
import { useQueryString } from './query-string';
import { apiClient } from './api-client';
import { useSession } from './session';
import { useHistory } from 'react-router-dom';

export function GoogleCallback() {
  const history = useHistory();
  const query = useQueryString();
  const { login } = useSession();

  useEffect(() => {
    apiClient.googleLogin(query).then(({ token }) => {
      login({ token });
      history.replace('/');
    });
  }, [history, login, query]);

  return <p>Loading...</p>;
}
