import React from 'react';
import Button from 'antd/es/button';
import styles from './login.css';

export function Login() {
  return (
    <div className={styles.container}>
      <h1>GroupRoulette</h1>
      <Button type='primary' href='/google-login'>
        Login with Google
      </Button>
    </div>
  );
}
