import axios from 'axios';
import { formatQueryString } from './query-string';

function authHeaders(token) {
  return { Authorization: 'Bearer ' + token };
}

class ApiClient {
  constructor() {
    this.client = axios.create({
      baseURL: 'https://api.grouproulette.space',
      paramsSerializer: formatQueryString,
    });
  }

  setSession(token) {
    this.token = token;
  }

  fetchMe() {
    return this.client
      .get('/users/me', { headers: authHeaders(this.token) })
      .then(response => response.data.data);
  }

  googleLogin(params) {
    return this.client
      .get('/auth/google/callback', { params })
      .then(response => {
        const { token } = response.data.data;
        this.token = token;

        return { token };
      });
  }
}

export const apiClient = new ApiClient();
