import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export function formatQueryString(values) {
  return qs.stringify(values);
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

export function useQueryString() {
  const { search } = useLocation();
  return useMemo(() => parseQueryString(search), [search]);
}
