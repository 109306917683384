export const socketEvents = {
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  AUTHENTICATION_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
  CHANNEL_STATUS: 'CHANNEL_STATUS',
  JOIN_CHANNEL: 'JOIN_CHANNEL',
  LEAVE_CHANNEL: 'LEAVE_CHANNEL',
  USER_JOINED: 'USER_JOINED',
  USER_LEFT: 'USER_LEFT',
  START_CONNECTION: 'START_CONNECTION',
  FINISH_CONNECTION: 'FINISH_CONNECTION',
};

export function socketActor({ socket, debug }) {
  return (send, receive) => {
    Object.values(socketEvents).forEach(event => {
      socket.on(event, message => {
        if (debug) {
          console.log('message received:', event, message);
        }
        send({ type: event, message });
      });
    });

    receive(event => {
      console.log('message sent:', event.type, event.message);
      socket.emit(event.type, event.message);
    });
  };
}
