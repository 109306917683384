import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { Machine, actions } from 'xstate';
import { useMachine } from '@xstate/react';
import { apiClient } from './api-client';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

const sessionMachine = new Machine({
  context: {
    token: null,
  },
  initial: 'authenticating',
  states: {
    authenticating: {
      invoke: {
        src: 'initialize',
        onDone: {
          actions: [
            actions.assign({
              token: (ctx, { data }) => data.token,
            }),
          ],
          target: 'authenticated',
        },
        onError: 'unauthenticated',
      },
    },
    authenticated: {
      on: {
        LOGOUT: {
          actions: [
            actions.assign({
              token: () => null,
            }),
          ],
          target: 'authenticated',
        },
      },
    },
    unauthenticated: {
      on: {
        LOGIN: {
          actions: [
            actions.assign({
              token: (ctx, { token }) => token,
            }),
          ],
          target: 'authenticated',
        },
      },
    },
  },
});

export function SessionProvider({ children }) {
  const [state, send] = useMachine(sessionMachine, {
    services: {
      initialize: () => {
        const token = localStorage.getItem('token');
        if (!token) {
          return Promise.reject('No token');
        }
        apiClient.setSession(token);
        return apiClient.fetchMe().then(user => ({ token }));
      },
    },
  });

  const logout = useCallback(() => send('LOGOUT'), [send]);
  const login = useCallback(
    ({ user, token }) => {
      localStorage.setItem('token', token);
      send({ type: 'LOGIN', user, token });
    },
    [send]
  );

  const session = useMemo(
    () => ({
      sessionState: state.value,
      token: state.context.token,
      login,
      logout,
    }),
    [login, logout, state]
  );

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}
