import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home } from './home';
import { Login } from './login';
import { GoogleCallback } from './google-callback';
import { useSession } from './session';

export function App() {
  const { sessionState } = useSession();

  return (
    <Switch>
      <Route path='/google-callback'>
        <GoogleCallback />
      </Route>
      <Route path='/'>
        {sessionState === 'authenticated' ? <Home /> : <Login />}
      </Route>
    </Switch>
  );
}
