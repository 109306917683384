/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import useUserMedia from 'react-use-user-media';
import { useService } from '@xstate/react';
import ErrorBoundary from 'react-error-boundary';

const constraints = {
  audio: true,
  video: {
    width: 800,
    height: 600,
  },
};

function useVideoStream(stream) {
  const ref = useRef();

  useEffect(() => {
    if (!stream || !ref.current) {
      return;
    }

    const video = ref.current;

    if ('srcObject' in video) {
      video.srcObject = stream;
    } else {
      // Avoid using this in new browsers, as it is going away.
      video.src = URL.createObjectURL(stream);
    }
    video.onloadedmetadata = () => {
      video.play();
    };
  }, [stream]);

  return ref;
}

function ConnectionError({ componentStack, error }) {
  return (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Here’s what we know…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );
}

function PendingUser({ user }) {
  return <p>Pending: {user.fullName}</p>;
}

function ConnectedUser({ user, connection, localStream }) {
  const [state, send] = useService(connection);
  const { stream } = state.context;
  const ref = useVideoStream(stream);

  useEffect(() => {
    if (localStream) {
      send({ type: 'STREAM', stream: localStream });
    }
  }, [localStream, send]);

  if (!state.matches('connected')) {
    return <p>Connecting: {user.fullName}</p>;
  }

  return (
    <>
      <p>Connected: {user.fullName}</p>
      <video ref={ref} />
    </>
  );
}

export function Group({ users, connections }) {
  const { state, stream } = useUserMedia(constraints);
  const ref = useVideoStream(stream);

  return (
    <div>
      <video ref={ref} muted />

      {users.map(user => {
        const connection = connections.get(user.id);

        if (connection) {
          return (
            <ErrorBoundary key={user.id} FallbackComponent={ConnectionError}>
              <ConnectedUser
                user={user}
                connection={connection}
                localStream={state === 'resolved' && stream}
              />
            </ErrorBoundary>
          );
        }

        return <PendingUser key={user.id} user={user} />;
      })}
    </div>
  );
}
