import React from 'react';
import styles from './home.css';
import { useSession } from './session';
import { socketMachine } from './socket';
import { useMachine } from '@xstate/react';
import { Group } from './group';

export function Home() {
  const { token } = useSession();
  const [state, send] = useMachine(socketMachine, {
    context: { token },
  });

  if (state.matches('initializing')) {
    return 'Loading...';
  }

  if (state.matches('error')) {
    return 'Woops... Something went boom boom...';
  }

  const { user, users, connections } = state.context;

  return (
    <div className={styles.container}>
      <h1>GroupRoulette</h1>
      <h2>Welcome {user.fullName}!</h2>

      {state.matches('waiting') && (
        <>
          <p>Waiting for more people to join...</p>
          <p>Others:</p>
          <ul>
            {users.map(user => (
              <li key={user.id}>{user.fullName}</li>
            ))}
          </ul>
        </>
      )}

      {state.matches('active') && (
        <Group users={users} connections={connections} />
      )}
    </div>
  );
}
