import { Machine, actions } from 'xstate';
import { socketActor, socketEvents } from './socket-actor';
import SimplePeer from 'simple-peer';

export const connectionMachine = new Machine(
  {
    context: {
      initiator: true,
      stream: null,
      socket: null,
      targetUser: null,
    },
    invoke: [
      {
        id: 'peer',
        src: ({ initiator, remoteSignal }) => (send, receive) => {
          const peer = new SimplePeer({
            initiator,
            trickle: false,
            config: {
              iceServers: [
                // { urls: 'stun:stun.services.mozilla.com:3478' },
                { urls: 'stun:stun.l.google.com:19302' },
              ],
            },
          });

          peer.on('connect', () => send('PEER_CONNECTED'));
          peer.on('signal', signal => {
            console.log('local signal', signal);
            send({ type: 'PEER_SIGNAL', signal });
          });
          peer.on('stream', stream => send({ type: 'PEER_STREAM', stream }));
          peer.on('close', () => send('PEER_DISCONNECTED'));
          peer.on('error', error => {
            console.log('peer error', error);
            send({ type: 'PEER_ERROR', error });
          });

          if (remoteSignal) {
            console.log('remote signal', remoteSignal);
            peer.signal(remoteSignal);
          }

          receive(event => {
            console.log('peer received event:', event);
            if (event.type === 'SIGNAL') {
              console.log('remote signal', event.signal);
              peer.signal(event.signal);
            } else if (event.type === 'STREAM') {
              console.log('stream event', event);
              peer.addStream(event.stream);
            } else if (event.type === 'CLOSE_CONNECTION') {
              peer.destroy();
            }
          });
        },
      },
      {
        id: 'socket',
        src: ({ socket }) => socketActor({ socket, debug: false }),
      },
    ],
    on: {
      PEER_STREAM: {
        actions: [actions.assign({ stream: (ctx, { stream }) => stream })],
      },
      STREAM: {
        actions: [actions.forwardTo('peer')],
      },
      CLOSE_CONNECTION: {
        actions: [actions.forwardTo('peer')],
      },
      PEER_SIGNAL: {
        actions: ['sendLocalSignal'],
      },
      [socketEvents.START_CONNECTION]: {
        cond: 'isTargetUser',
        actions: ['receiveRemoteSignal'],
      },
      [socketEvents.FINISH_CONNECTION]: {
        cond: 'isTargetUser',
        actions: ['receiveRemoteSignal'],
      },
      PEER_ERROR: {
        actions: [actions.assign({ error: (ctx, { error }) => error })],
        target: '.error',
      },
      PEER_CONNECTED: '.connected',
      PEER_DISCONNECTED: '.disconnected',
    },
    initial: 'connecting',
    states: {
      connecting: {},
      connected: {},
      disconnected: {},
      error: {},
    },
  },
  {
    guards: {
      isTargetUser: ({ targetUser }, { message }) => {
        return targetUser.id === message.user.id;
      },
    },
    actions: {
      sendLocalSignal: actions.send(
        ({ peer, targetUser }, { signal }) => ({
          type:
            signal.type === 'offer'
              ? socketEvents.START_CONNECTION
              : socketEvents.FINISH_CONNECTION,
          message: {
            signal,
            socketId: targetUser.socketId,
          },
        }),
        { to: 'socket' }
      ),
      receiveRemoteSignal: actions.send(
        (ctx, { message }) => ({
          type: 'SIGNAL',
          signal: message.signal,
        }),
        { to: 'peer' }
      ),
    },
  }
);
