import io from 'socket.io-client';
import { Machine, actions, spawn } from 'xstate';
import { socketActor, socketEvents } from './socket-actor';
import { connectionMachine } from './connection-machine';

export const socketMachine = new Machine(
  {
    context: {
      token: null,
      user: null,
      channel: null,
      users: [],
      socket: null,
      socketActor: null,
      connections: new Map(),
    },
    initial: 'initializing',
    states: {
      initializing: {
        entry: [
          actions.assign({
            socket: () => {
              return io('https://api.grouproulette.space');
            },
          }),
          actions.assign({
            socketActor: ({ socket }) => {
              return spawn(socketActor({ socket, debug: true }), 'socket');
            },
          }),
          actions.send(
            ({ token }) => ({ type: 'AUTHENTICATE', message: token }),
            { to: 'socket' }
          ),
        ],
        on: {
          [socketEvents.AUTHENTICATION_SUCCESSFUL]: {
            actions: ['setUser'],
            target: 'waiting',
          },
          [socketEvents.AUTHENTICATION_FAILED]: 'error',
        },
      },
      error: {},
      waiting: {
        on: {
          [socketEvents.CHANNEL_STATUS]: [
            { cond: 'isWaitingChannel' },
            {
              actions: ['setUsers', 'setChannel'],
              target: 'active',
            },
          ],
          [socketEvents.JOIN_CHANNEL]: [
            { cond: 'isWaitingChannel' },
            {
              actions: ['clearUsers', 'setChannel'],
              target: 'active',
            },
          ],
        },
      },
      active: {
        exit: ['closeAllConnections', 'clearUsers'],
        on: {
          [socketEvents.USER_JOINED]: {
            actions: [
              'addUser',
              actions.assign({
                connections: ({ connections, socket }, { message }) => {
                  const { user } = message;
                  const newConnection = spawn(
                    connectionMachine.withContext({
                      socket,
                      targetUser: user,
                      initiator: true,
                    }),
                    'connection-' + user.id
                  );

                  return connections.set(user.id, newConnection);
                },
              }),
            ],
          },
          [socketEvents.USER_LEFT]: {
            actions: ['closeConnection', 'removeUser'],
          },
          [socketEvents.START_CONNECTION]: {
            cond: ({ connections }, { message }) => {
              return !connections.has(message.user.id);
            },
            actions: [
              actions.assign({
                connections: ({ connections, socket }, { message }) => {
                  const { user, signal } = message;
                  const newConnection = spawn(
                    connectionMachine.withContext({
                      socket,
                      targetUser: user,
                      initiator: false,
                      remoteSignal: signal,
                    }),
                    'connection-' + user.id
                  );

                  return connections.set(user.id, newConnection);
                },
              }),
            ],
          },
          [socketEvents.CHANNEL_STATUS]: [
            {
              cond: 'isWaitingChannel',
              actions: ['setUsers', 'setChannel'],
              target: 'waiting',
            },
            {
              cond: ({ channel }, { message }) => {
                return channel !== message.channel;
              },
              actions: ['setUsers', 'setChannel'],
              target: 'active',
            },
            {
              actions: ['setUsers'],
            },
          ],
          [socketEvents.LEAVE_CHANNEL]: {
            target: 'waiting',
          },
        },
      },
    },
  },
  {
    actions: {
      closeAllConnections: actions.assign({
        connections: ({ connections }) => {
          connections.forEach(connection => {
            connection.send({ type: 'CLOSE_CONNECTION' });
            connection.stop();
          });
          connections.clear();

          return new Map();
        },
      }),
      clearUsers: actions.assign({
        users: () => [],
      }),
      closeConnection: actions.assign({
        connections: ({ connections }, { message }) => {
          const { user } = message;
          const connection = connections.get(user.id);

          if (connection) {
            connection.send({ type: 'CLOSE_CONNECTION' });
            connection.stop();
          }

          connections.delete(user.id);

          return connections;
        },
      }),
      removeUser: actions.assign({
        users: ({ users }, { message }) => {
          return users.filter(user => user.id !== message.user.id);
        },
      }),
      setUser: actions.assign({
        user: (ctx, { message }) => message,
      }),
      setUsers: actions.assign({
        users: (ctx, { message }) => message.users,
      }),
      addUser: actions.assign({
        users: ({ users }, { message }) => {
          return users
            .filter(user => user.id !== message.user.id)
            .concat(message.user);
        },
      }),
      setChannel: actions.assign({
        channel: (ctx, { message }) => message.channel,
      }),
    },
    guards: {
      isWaitingChannel: (ctx, { message }) => {
        return message.channel === 'WAITING_CHANNEL';
      },
    },
  }
);
